import React from 'react';
import NavBars from './common/NavBars'


function Contact() {
  return (
    <div>
      <NavBars />
      <div>Contact Info</div>
    </div>
  );
}

export default Contact;
