import React from 'react'
import NavBars from './common/NavBars'

function Preferences() {
  return (
   <div className='container'>
     <NavBars />
    <div>Preferences</div>
   </div>
  )
}

export default Preferences