// import { useState } from 'react';
import './App.css';
import Logo from './logo/Badreg.jpg';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { LoginSchema } from './common/YupSchema';
import { backendURI } from './common/Configs';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authLogin } from './features/authSlice';
import { useState } from 'react';

function Login() {
  const dispatch = useDispatch();
  const [errmessage, setErrmessage] = useState(false);

  const loginUser = (values) => {
    axios
      .post(`${backendURI}user/login`, {
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        console.log(res.data);
        // dispatch(authLogin({ responseData: res.data }));
        res.data.error ? setErrmessage(true) : dispatch(authLogin({ responseData: res.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <Formik initialValues={{ email: '', password: '' }} validationSchema={LoginSchema} onSubmit={(values) => loginUser(values)}>
            {({ touched, errors, isSubmitting, values }) => (
              <div>
                <div className="row mb-3">
                  <div className="col-lg-12 text-center">
                    <img src={Logo} style={{ height: 120, width: 120 }} alt="logo" />
                  </div>
                </div>
                <Form>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      className={`mt-2 form-control
                          ${touched.email && errors.email ? 'is-invalid' : ''}`}
                    />

                    <ErrorMessage component="div" name="email" className="invalid-feedback" />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password" className="mt-3">
                      Password
                    </label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Enter password"
                      className={`mt-2 form-control
                          ${touched.password && errors.password ? 'is-invalid' : ''}`}
                    />
                    <ErrorMessage component="div" name="password" className="invalid-feedback" />
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12 text-center">
                      <button type="submit" style={{ borderColor: '#F59028', backgroundColor: '#F59028', color: '#fff', fontWeight: 'bold' }} className="btn btn-primary btn-block mt-4">
                        LOGIN
                      </button>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12 text-center">
                      <Link to="/">REGISTER</Link>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Login;
