import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  data: {},
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authLogin: (state, action) => {
      return {
        ...state,
        isAuthenticated: true,
        data: action.payload.responseData,
      };
    },
    authLogOut: (state, action) => {
      return { ...state, isAuthenticated: false, data: {} };
    },
  },
});

export const { authLogin, authActivate } = AuthSlice.actions;
export default AuthSlice.reducer;
