import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from '../features/authSlice';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// import { setupListeners } from '@reduxjs/toolkit/query';
import { registerApi } from '../services/RtkRegister';

const persistConfig = {
  key: 'root',
  versiion: 1,
  storage,
};

const reducer = combineReducers({
  Auth: authReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: {
    persistedReducer,
    [registerApi.reducerPath]: registerApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(registerApi.middleware),
});

const persistor = persistStore(store);

export default store;
export { persistor };
