import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import Preferences from './Preferences';
import Contact from './Contact';
import { useSelector } from 'react-redux';

function App() {
  const { Auth } = useSelector((state) => state.persistedReducer);
  console.log(Auth);
  return (
    <BrowserRouter>
      <Routes>
        {!Auth.isAuthenticated && (
          <>
            <Route path="/" element={<Register />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Register" element={<Register />} />
          </>
        )}
        {Auth.isAuthenticated && (
          <>
            <Route path="*" element={<Preferences />} />
            <Route path="/Preferences" element={<Preferences />} />
            <Route path="/Contact" element={<Contact />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
