import React, { useEffect, useState } from 'react';
import Logo from './logo/Badreg.jpg';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { RegisterSchema } from './common/YupSchema';
import { backendURI } from './common/Configs';
// import { useGetSpecialityQuery } from './services/RtkRegister';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Register() {
  //   const { data, error, isLoading } = useGetSpecialityQuery();
  const [soecialities, setSpecialities] = useState([]);
  //   if (!isLoading) {  setSpecialities(data)   }
  useEffect(() => {
    getSpecialitiesAxios();
  }, []);

  const registerUser = (values) => {
    axios
      .post(`${backendURI}user`, {
        email: values.email,
        phonenum: values.phonenumber,
        fullname: values.fullname,
        password: values.password,
        addresses: values.addresses,
        speciality: values.speciality,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSpecialitiesAxios = () => {
    axios
      .get(`${backendURI}lookup/Speciality`)
      .then((results) => {
        setSpecialities(results.data);
        console.log(results.data);
      })
      .catch((errors) => console.log(errors));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <Formik initialValues={{ email: '', phonenumber: '', fullname: '', password: '', cpassword: '', addresses: '', speciality: '' }} validationSchema={RegisterSchema} onSubmit={(values) => registerUser(values)}>
            {({ touched, errors, isSubmitting, values }) =>
              !isSubmitting ? (
                <div>
                  <div className="row mb-3">
                    <div className="col-lg-12 text-center">
                      <img src={Logo} style={{ height: 120, width: 120 }} alt="logo" />
                    </div>
                  </div>
                  <Form>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        className={`mt-2 form-control
                          ${touched.email && errors.email ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage component="div" name="email" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="password" className="mt-3">
                        Password
                      </label>
                      <Field
                        type="password"
                        name="password"
                        placeholder="Enter password"
                        className={`mt-2 form-control
                          ${touched.password && errors.password ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage component="div" name="password" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="cpassword" className="mt-3">
                        Confirm Password
                      </label>
                      <Field
                        type="password"
                        name="cpassword"
                        placeholder="Enter password"
                        className={`mt-2 form-control
                          ${touched.cpassword && errors.cpassword ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage component="div" name="cpassword" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="phonenumber"> Telephone </label>
                      <Field
                        type="text"
                        name="phonenumber"
                        placeholder="Enter Phone Number"
                        className={`mt-2 form-control
                          ${touched.phonenumber && errors.phonenumber ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage component="div" name="phonenumber" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="fullname">Full Name</label>
                      <Field
                        type="text"
                        name="fullname"
                        placeholder="Enter Full Name"
                        className={`mt-2 form-control
                          ${touched.fullname && errors.fullname ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage component="div" name="fullname" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="addresses">Address</label>
                      <Field
                        type="text"
                        name="addresses"
                        placeholder="Enter Full Address"
                        className={`mt-2 form-control
                          ${touched.addresses && errors.addresses ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage component="div" name="addresses" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="speciality">Address</label>
                      <Field
                        name="speciality"
                        component="select"
                        className={`mt-2 form-control
                          ${touched.speciality && errors.speciality ? 'is-invalid' : ''}`}
                      >
                        <option value="">Select Speciality</option>
                        {soecialities.map((spec) => {
                          return (
                            <option key={spec.id} value={spec.id}>
                              {spec.description}
                            </option>
                          );
                        })}
                      </Field>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-12 text-center">
                        <button type="submit" style={{ borderColor: '#F59028', backgroundColor: '#F59028', color: '#fff', fontWeight: 'bold' }} className="btn btn-primary btn-block mt-4">
                          REGISTER
                        </button>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-12 text-center">
                        You have account? <Link to="/Login">LOGIN</Link>
                      </div>
                    </div>
                  </Form>
                </div>
              ) : (
                <div>
                  <div className="row mb-3">
                    <div className="col-lg-12 text-center">
                      <img src={Logo} style={{ height: 120, width: 120 }} alt="logo" />
                    </div>
                  </div>
                  <div className="alert alert-success mt-3">Thanks for subscribing to the evenmt.</div>
                  <p>
                    Dear <b> {values.fullname} </b>
                  </p>
                  <p> Thanks for sibscribing to our sponsored meeting.</p>
                  <p>
                    please check your mail (<b>{values.email}</b>). we have sent an activation link. click on the link and login to our website. you will be able to choose your meal preference, so that we can arrange everything you need.
                  </p>
                  <p> Regards </p>
                </div>
              )
            }
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Register;
