import * as Yup from 'yup';

const RegisterSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address format').required('Email is required'),
  phonenumber: Yup.number().typeError("That doesn't look like a phone number").positive("A phone number can't start with a minus").integer("A phone number can't include a decimal point").min(8).required('A phone number is required'),
  fullname: Yup.string().min(3, 'Full Name must be 10 characters at minimum').required('Full Name Number is required'),
  password: Yup.string().min(3, 'Password must be 3 characters at minimum').required('Password is required'),
  cpassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  speciality: Yup.string().required('Select your speciality'),
  addresses: Yup.string().required('Enter your addresses'),
});

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address format').required('Email is required'),
  password: Yup.string().min(3, 'Password must be 3 characters at minimum').required('Password is required'),
});

export { RegisterSchema, LoginSchema };
